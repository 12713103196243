
export type TPopupErrorType = 'script' | 'indexDb_old' | 'authorisation_error' | 'save_error' | 'variable';

export const errorsPopupCatalog: Record<TPopupErrorType, string> = {
    authorisation_error: 'Ошибка авторизации.<br>Пожалуйста, попробуйте снова или обратитесь в техподдержку <a href="https://t.me/RoboCodeHelp_bot" target="_blank">t.me/RoboCodeHelp_bot</a>',
    indexDb_old: 'База данных устарела.<br>Пожалуйста, перезагрузите страницу',
    script: 'Не удалось открыть скрипт.<br>Неизвестная ошибка сервера',
    save_error: 'Ошибка при сохранении',
    variable: 'Не удалось создать переменную.<br>Переменная с таким именем уже существует'
}
